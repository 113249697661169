.wrapper {
    margin-top: 24px;
    margin-bottom: 30px;
    padding: 0px 32px;
    flex: 1;
}

.modal {
    background-color: var(--white-color);
}
