.top {
    margin-bottom: 80px;
    color: #292929;
}

.heading {
    color: #242424;
    font-size: 2.8rem;
    font-weight: 900;
    margin: 14px 0;
}

.desc {
    margin: 15px 0;
    font-size: 1.5rem;
}

.body {
    .container {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.leftLayout {
    padding: 0 64px 44px 0;
}

.msg {
    padding: 16px 0;

    a {
        color: var(--primary-color);
        text-decoration: underline;
    }
}

.rightLayout {
    height: 100%;
}

@media (min-width: 0) and (max-width: 1023px) {
    .top {
        margin-bottom: 48px;
    }

    .leftLayout {
        padding: 0;
    }
}
