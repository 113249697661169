.wrapper {
    position: fixed;
    top: 72px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 8px;
    z-index: 1000;
}

.container {
    animation: appear 0.25s cubic-bezier(0, 0.2, 0.2, 1) 1;
    background-color: var(--text-color);
    color: #fff;
    font-size: 1.4rem;
    border-radius: 4px;
    display: inline-block;
    padding: 12px 16px;
    text-align: center;
    min-width: 200px;
    max-width: calc(100vw - 80px);
}

@keyframes appear {
    from {
        opacity: 0;
        transform: translateY(-100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 740px) {
    .container {
        width: 100%;
    }
}
