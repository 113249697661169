.wrapper {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%), 0 0 1px 0 rgb(0 0 0 / 10%);
    margin-bottom: 20px;
    padding: 18px 14px;
}

.title {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 8px;
}
