.cover {
    background: top/cover no-repeat;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-top: calc(0px - var(--header-height));
    padding-top: 28%;
    position: relative;
    width: 100%;
}

.user {
    position: absolute;
    display: flex;
    align-items: flex-end;
    bottom: -70px;
    left: 40px;
}

.user-avatar {
    border-radius: 50%;
    background-color: #fff;
    height: 168px;
    padding: 4px;
    width: 168px;

    img {
        border-radius: 50%;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.username {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 16px;
    margin-left: 16px;
    text-align: center;
}

.wrapper {
    margin-top: 90px;
    padding: 0 24px;
}

.bio {
    border-bottom: 1px solid #d0d7de;
    font-size: 1.4rem;
    margin-top: 20px;
    padding-bottom: 15px;
    text-align: center;
}

.participate-time {
    display: flex;
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-top: 15px;

    strong {
        font-weight: 600;
    }
}

.participate-time-icon {
    color: #808990;
    margin: 2px 8px 0 0;
    text-align: center;
    width: 20px;
}

.post {
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 12px;

    &:not(:last-child) {
        border-bottom: 1px solid #d0d7de;
    }
}

.thumb {
    flex-shrink: 0;
    height: 128px;
    margin-right: 24px;
    width: 228px;

    img {
        border-radius: 16px;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

.info-title {
    font-size: 1.6rem;
    font-weight: 600;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
}

.info-desc {
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-top: 4px;
    overflow: hidden;
}

.msg {
    font-size: 1.4rem;
}

@media (min-width: 1024px) {
    .container {
        padding: 0 20px !important;
    }
}

@media (min-width: 0) and (max-width: 739px) {
    .user {
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        bottom: -122px;
        flex-direction: column;
        align-items: center;
    }

    .user-avatar {
        height: 128px;
        width: 128px;
    }

    .username {
        font-size: 2.4rem;
        margin: 6px 0 0 0;
    }

    .wrapper {
        margin-top: 150px;
    }

    .post {
        flex-direction: column;
    }

    .thumb {
        height: auto;
        margin-bottom: 12px;
        margin-right: 0;
        width: 100%;
    }
}
