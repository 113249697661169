.top {
    margin-bottom: 80px;
}

.heading {
    margin: 18px 0;
    color: #242424;
    font-size: 2.8rem;
    font-weight: 900;
}

.noResult {
    font-size: 1.4rem;
    line-height: 1.6;
    margin-top: 32px;

    p {
        margin: 14px 0;
    }

    a {
        color: var(--secondary-color);
        font-weight: 600;
        text-decoration: underline;
    }
}
