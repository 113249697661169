.wrapper {
    border: 2px solid #e8e8e8;
    border-radius: 16px;
    margin-top: 16px;
    padding: 16px 24px 22px;
    position: relative;

    h3 {
        margin-bottom: 11px;
        padding-right: 24px;
    }
}

.author {
    color: var(--desc-color);
    font-size: 1.4rem;

    a {
        color: var(--secondary-color);
    }
}

.dot {
    padding: 0 6px;
}

.options {
    position: absolute;
    right: 16px;
    top: 8px;
    color: #666;
    font-size: 1.6rem;
    margin-left: 4px;
    padding: 4px 8px;
    background-color: transparent;
    cursor: pointer;
}

.menu {
    min-width: 200px;

    a {
        display: block;
        color: #444;
        font-size: 1.4rem;
        padding: 12px 20px;
        transition: 0.3s;

        &:hover {
            background-color: #f0f0f0;
            color: #292929;
        }
    }
}
