.wrapper {
    width: 100%;

    ul {
        margin: 16px 0 24px;
        list-style: none;

        li {
            padding-bottom: 0.4em;
            text-align: center;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 1.6;
        }
    }

    p {
        margin: 15px 0;
    }
}

.header {
    display: flex;
    align-items: center;
    padding: 20px;
    color: #242424;

    img {
        height: 48px;
        width: 48px;
    }

    h3 {
        font-size: 1.4rem;
        font-weight: 900;
        margin-left: 12px;
    }
}

.content {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
}

.error-code {
    background-image: url(../../assets/images/not-found-gradient.jpg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-mask-image: url(../../assets/images/not-found-word.svg);
    mask-image: url(../../assets/images/not-found-word.svg);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: auto;
    mask-size: auto;
    width: 100%;
    height: 200px;
    margin: 18px 0;
}

.error-title {
    font-size: 45px;
    font-weight: 900;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 30px;
}

.copyright {
    position: absolute;
    bottom: 20px;
    width: 100%;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    color: #666;
}

.btn-link {
    padding: 13px 28px;
}

.my-post-link {
    background-color: #fff !important;
    border: none !important;
    color: #333 !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    padding: 9px 16px 9px 8px;

    &:hover {
        color: var(--primary-color) !important;
    }
}

@media (max-width: 739px) {
    .header,
    .copyright {
        display: none;
    }

    .content {
        padding: 0 16px;
        width: 100%;
    }
}
