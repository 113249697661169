.select {
    width: 100%;
    min-height: 47px;
    font-size: 1.4rem;
    font-family: inherit;
    background-color: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.15);
    min-height: 52px;
    padding: 0 8px;
}
