.wrapper {
    position: sticky;
    position: -webkit-sticky;
    top: 74px;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 4px 8px 0;
    width: var(--sidebar-width);
    z-index: 10;
}

.list {
    margin: 10px 0;
    list-style-type: none;

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 72px;
        width: 72px;
        margin-top: 4px;
        border-radius: 16px;
        color: #404040;

        &:hover {
            background-color: #f5f5f5;
            color: #1a1a1a;
        }
    }

    a.active {
        background-color: #e8ebed;
        color: #1a1a1a;
    }

    svg {
        font-size: 1.8rem;
    }

    span {
        font-size: 1.1rem;
        font-weight: 600;
        margin-top: 6px;
    }
}
