.wrapper {
    border: 2px solid #e8e8e8;
    border-radius: 16px;
    padding: 24px;

    &:not(:first-child) {
        margin-top: 16px;
    }
}

.body,
.header {
    display: flex;
    align-items: center;
}

.header {
    justify-content: space-between;

    img {
        border-radius: 50%;
        flex-shrink: 0;
        height: 20px;
        object-fit: cover;
        width: 20px;
    }

    span {
        color: #292929;
        font-size: 1.2rem;
        font-weight: 600;
        margin-left: 8px;
    }
}

.author {
    display: flex;
    align-items: center;

    a {
        display: inherit;
    }
}

.info {
    font-size: 1.4rem;
    padding-right: 32px;
    flex: 1 1;

    h3 {
        color: #292929;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.6;
        margin-bottom: 0;
        margin-top: 8px;
    }

    p {
        color: #505050;
        font-size: 1.5rem;
        line-height: 1.6;
        margin: 4px 0 15px;
    }

    .dot {
        margin: 0 8px;
    }
}
