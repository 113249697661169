.wrapper {
    display: flex;
    inset: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    z-index: 9999;

    &.fadeIn {
        animation-name: fadeIn;
        -webkit-animation-name: fadeIn;
        animation-timing-function: ease;
        -webkit-animation-timing-function: ease;
    }

    &.fadeOut {
        animation-name: fadeOut;
        -webkit-animation-name: fadeOut;
        animation-timing-function: ease;
        -webkit-animation-timing-function: ease;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
