.wrapper {
    margin-top: 54px;
    padding-bottom: 48px;
}

.heading {
    color: var(--text-color);
    font-size: 3rem;
    font-weight: 600;
    margin: 18px 0;
}

@media (min-width: 0px) and (max-width: 739px) {
    .wrapper {
        margin-top: 24px;
        padding: 0 16px;
    }
}
