.btn {
    display: inline-block;
    background-color: var(--white-color);
    border: 1px solid var(--primary-color);
    border-radius: 999px;
    color: var(--primary-color);
    font-size: 1.4rem;
    font-weight: 600;
    font-family: var(--font-family);
    padding: 6px 20px;
    transition: 0.3s ease;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.large {
        font-size: 1.6rem;
        padding: 11px 28px;
    }

    &.primary {
        background-color: var(--primary-color);
        color: var(--white-color);
    }

    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }

    &.disabled {
        opacity: 0.5 !important;
        pointer-events: none !important;
    }

    &.fieldButton {
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 30px;
        color: rgba(0, 0, 0, 0.54);
        cursor: pointer;
        font-size: 1.4rem;
        font-weight: 400;
        height: 37px;
        line-height: 37px;
        padding: 0 16px;
        transition: background-color 0.1s, border-color 0.1s, color 0.1s, fill 0.1s;
    }

    &.fieldButtonDefault {
        &:hover {
            border-color: rgba(0, 0, 0, 0.54);
            color: rgba(0, 0, 0, 0.68);
        }
    }

    &.fieldButtonSave {
        border-color: var(--primary-color);
        color: var(--primary-color);
        margin-right: 8px;
    }
}
