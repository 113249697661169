.title {
    color: rgb(41, 41, 41);
    font-size: 3.2rem;
    font-weight: 500;
    min-height: 48px;
    width: 100%;
}

.editor {
    margin-top: 16px;
}
