.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: var(--header-height);
    background-color: #fff;
    border-bottom: 1px solid #e8ebed;
    padding: 0 28px;
    z-index: 99;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    will-change: transform;

    &.transparent {
        border-bottom: none;
        background: transparent;
    }

    &.moveOut {
        -webkit-transform: translateY(calc(var(--header-height) * -1));
        transform: translateY(calc(var(--header-height) * -1));
    }
}

.logo,
.body,
.actions {
    display: flex;
    align-items: center;
    flex: 1;
}

.logo {
    img {
        border-radius: 6px;
        height: 38px;
        width: 38px;
    }

    a {
        display: flex;
    }
}

.goBack {
    .logoHeading {
        display: flex;
        align-items: center;
        color: #808990;
        cursor: pointer;
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    &:hover svg {
        -webkit-transform: translateX(-4px);
        transform: translateX(-4px);
    }

    svg {
        font-size: 1.4rem;
        margin-right: 4px;
        transition: 0.3s ease;
    }
}

.logoHeading {
    color: var(--black-color);
    font-size: 1.4rem;
    font-weight: 700;
    margin-left: 14px;
}

.body {
    justify-content: center;
}

.actions {
    justify-content: flex-end;
    font-size: 1.4rem;
    font-weight: 600;
}

.actionBtn {
    padding: 8px 12px;
    position: relative;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;

    svg {
        color: #707070;
        font-size: 1.8rem;
    }
}

.myPosts {
    margin-right: 16px;
    padding: 8px;
}

.avatar {
    border-radius: 50%;
    object-fit: cover;
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.publishBtn {
    margin-right: 16px;
    font-weight: 400;
}

@media (min-width: 740px) and (max-width: 1023px) {
    .wrapper {
        padding: 0 32px;
    }

    .myPosts {
        margin-right: 0;
    }
}

@media (min-width: 0) and (max-width: 739px) {
    .wrapper {
        padding: 0 16px;
    }

    .actions {
        flex: 5 1;
    }

    .loginBtn {
        margin-left: 16px;
    }
}
