.withSidebar {
    flex-shrink: 0;
}

.withSidebarContent {
    flex: 1;
    max-width: calc(100% - var(--sidebar-width));
    padding: 0 40px 0 20px;
}

.content {
    margin-bottom: 60px;
    padding: 8px 44px 0;
}

@media (max-width: 1023px) {
    .withSidebarContent {
        max-width: 100%;
        padding: 0 32px;
    }

    .content {
        margin-bottom: 48px;
        padding: 0;
    }
}

@media (min-width: 0px) and (max-width: 739px) {
    .withSidebarContent {
        padding: 0 16px;
    }
}
