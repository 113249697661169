.label {
    display: flex;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 28px;
    position: relative;
    user-select: none;
}

.input {
    display: none;
}

.input:checked ~ .box {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.input:checked ~ .box .tick {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.box {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: inline-block;
    flex-shrink: 0;
    height: 16px;
    width: 16px;
    margin: 6px 8px 0 0;
    background-color: var(--white-color);
    -webkit-transition: background-color 0.15s cubic-bezier(0.2, 0.2, 0.2, 1);
    transition: background-color 0.15s cubic-bezier(0.2, 0.2, 0.2, 1);
}

.tick {
    position: absolute;
    left: 0;
    top: 0;
    color: var(--white-color);
    width: 14px;
    height: 14px;
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transition: opacity 0.25s cubic-bezier(0, 0.2, 0.2, 1),
        -webkit-transform 0.25s cubic-bezier(0.15, 1.3, 0.7, 1.25) 0.2s;
    transition: opacity 0.25s cubic-bezier(0, 0.2, 0.2, 1),
        -webkit-transform 0.25s cubic-bezier(0.15, 1.3, 0.7, 1.25) 0.2s;
    transition: opacity 0.25s cubic-bezier(0, 0.2, 0.2, 1),
        transform 0.25s cubic-bezier(0.15, 1.3, 0.7, 1.25) 0.2s;
    transition: opacity 0.25s cubic-bezier(0, 0.2, 0.2, 1),
        transform 0.25s cubic-bezier(0.15, 1.3, 0.7, 1.25) 0.2s,
        -webkit-transform 0.25s cubic-bezier(0.15, 1.3, 0.7, 1.25) 0.2s;
    z-index: 6;
}

.text {
    flex-grow: 1;
    margin: 0 8px 0 0;
}
