@import 'normalize.css';
@import 'MDEditor.scss';

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');

:root {
    --primary-color: #f05123;
    --secondary-color: #029e74;
    --white-color: #fff;
    --black-color: #000;
    --text-color: #333;
    --desc-color: #0000008a;

    --font-family: 'Montserrat', Arial, Helvetica, sans-serif;

    --header-height: 66px;
    --sidebar-width: 96px;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
}

body {
    font-family: var(--font-family);
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--text-color);
    text-rendering: optimizeSpeed;
    overflow-y: overlay;
    overscroll-behavior: none;
}

:not(.w-md-editor-text-input)::selection {
    background-color: var(--primary-color);
    color: #fff;
}

.d-flex {
    display: flex;
}

.al-center {
    align-items: center;
}

.d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

input,
button {
    border: none;
    outline: none;
    font-family: inherit;
}

.disabled {
    opacity: 0.5 !important;
    pointer-events: none !important;
}

a {
    color: var(--text-color);
    text-decoration: none;
}

.svg-inline--fa {
    display: var(--fa-display, inline-block);
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}

svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
    overflow: visible;
    box-sizing: content-box;
}

// Selection
@media (min-width: 1024px) {
    html::-webkit-scrollbar {
        border-radius: 0;
        height: 8px;
        width: 8px;
    }
}

@media (min-width: 1024px) {
    html::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 4px;
    }
}

@media (min-width: 1024px) {
    html::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 0;
    }
}

// React grid system styles
@media (min-width: 1024px) and (max-width: 1239px) {
    .grid-container {
        width: 984px !important;
        flex: unset !important;
    }
}

@media (min-width: 740px) and (max-width: 1023px) {
    .grid-container {
        max-width: 644px !important;
    }
}

@media (min-width: 0px) and (max-width: 739px) {
    .grid-container {
        max-width: 100% !important;
    }
}
