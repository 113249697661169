.wrapper {
    position: relative;
    width: 420px;
    border: 2px solid #e8e8e8;
    border-radius: 20px;
    height: 40px;
    padding: 0 16px 0 8px;
    transition: border-color 0.2s ease-in-out;

    input {
        border: none;
        caret-color: #444;
        flex: 1 1;
        height: 100%;
        outline: none;
        padding: 0 4px;
    }

    &:focus-within {
        border-color: #444;
    }
}

.icon {
    background-color: #fff;
    background-image: url('/assets/images/search-icon.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 18px;
    border-radius: 50%;
    height: 32px;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
    width: 30px;

    &:hover {
        opacity: 1;
    }
}

.result {
    color: #0000008a;
    font-size: 1.4rem;
    max-height: calc(90vh - 66px);
    min-height: 50px;
    min-width: 230px;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding: 12px 24px;
    width: 420px;
    background-color: #fff;
    box-shadow: 0 -4px 32px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.header {
    align-items: center;
    display: flex;
    padding: 6px 0 0;

    span {
        margin-left: 8px;
    }
}

.heading {
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    padding: 24px 0 12px;
    font-size: 1.4rem;

    h5 {
        font-weight: 500;
        font-size: 1.4rem;
        text-transform: uppercase;
    }
}

.see-more {
    &:hover {
        color: var(--primary-color);
    }
}

.search-item {
    align-items: center;
    display: flex;
    padding: 6px 0;
}

.search-img {
    border-radius: 50%;
    height: 32px;
    object-fit: cover;
    object-position: center;
    width: 32px;
}

.search-details {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.search-title {
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #292929;
    display: -webkit-box;
    font-size: 1.3rem;
    line-height: 1.4;
    overflow: hidden;
    flex: 1;
    font-weight: 600;
}

.search-min-read {
    font-size: 1.1rem;
}

.search-blank {
    text-align: center;
    font-size: 1.3rem;
}
