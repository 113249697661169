.wrapper {
    h3 {
        color: #757575;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.8;
        text-transform: uppercase;
        margin: 14px 0;
    }
}

.list {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;
    padding-left: 0;
    list-style-type: none;

    a {
        background-color: #f2f2f2;
        border-radius: 30px;
        color: #333;
        display: block;
        font-weight: 500;
        line-height: 1.8;
        margin: 0 8px 8px 0;
        padding: 6px 16px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

@media (max-width: 1023px) {
    .wrapper {
        margin-bottom: 32px;
    }
}
